<template>
  <div id="view-enquire" class="view view-chapter view-enquire view-scrollable">
    <component
      v-if="viewLoaded"
      :is="componentInstance"
      :data="data"
      :leavingView="leavingView"
      :currentChapter="currentChapter"
      :part="partInfo"
      :show="showChild"
    ></component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  storeDataLoading,
  beforeLeaveRoute,
  loadImages,
} from "@/mixins/core.js";

export default {
  name: "ViewEnquire",
  mixins: [storeDataLoading, loadImages, beforeLeaveRoute],
  data() {
    return {
      data: null,
      viewLeaveDuration: 1100,
      viewEnterDelay: 200,
      loaderOpenDelay: 1000,
      leavingView: false,
      storeKey: "ENQUIRE",
      currentChapter: "page",
      partInfo: {
        id: false,
        title: "Enquire",
      },
    };
  },
  computed: {
    ...mapState(["menuButton"]),
    componentInstance() {
      return () => import("@/views/enquire/EnquireForm.vue");
    },
    imagesToLoad() {
      return Object.keys(this.data.images).map((key) => this.data.images[key]);
    },
  },
  created() {
    this.getPartData();
  },
  updated() {
    this.getPartData();
  },
  methods: {
    getPartData() {
      //load relevant data for this chapter
      this.loadPartData({
        url: "/pages/enquire.json",
        type: this.storeKey,
      });
    },
    viewLeaveAnimations(to, from) {
      this.leavingView = true;
    },
  },
  watch: {
    $route(to, from) {
      //modal
      if (to.path === from.path && to.has != from.hash) {
        return;
      }
    },
    viewDataLoaded(val) {
      if (val) {
        this.data = this.$store.state.data[this.storeKey].data;
        this.preloadImages(this.imagesToLoad, this.initView);
      }
    },
    viewReady(val) {
      if (val) {
        this.leavingView = false;
      } else {
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    //modal
    if (to.path === from.path && to.has != from.hash) {
      next();
    } else {
      //leave view gracefully
      this.leaveView(to, from, next);
    }
  },
  mounted() {},
};
</script>

<style scoped>
.view-part {
  padding: 60px 0 0;
  position: relative;
}
</style>
